/* eslint-disable react/no-danger */
import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { useStaticQuery, graphql, Link } from 'gatsby';

import DefaultLayout from '@/layouts/default';
import SEO from '@/components/layout/seo';
import Framework from '@/components/Framework';


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allIndicatorsXlsxIndicators(filter: { type: { eq: "overview" } }) {
        nodes {
          type
          summary
          area
          id
          group
          identifier
        }
      }
    }
  `);
  const indicators = data.allIndicatorsXlsxIndicators.nodes;

  return (
    <DefaultLayout pageInfo={{ pageName: 'index' }}>
      <SEO title="Home" keywords={[]} />
      <div className="home-hero">
        <Container className="pt-4 py-xl-5">
          <Row>
            <Col lg={6} className="pr-lg-5">
              <h1>Building High-Quality, Equitable Pre-K</h1>
              <p>
                The{' '}
                <strong>Partnership for Pre-K Improvement (PPI) Toolkit</strong>{' '}
                is designed to help state leaders, researchers and advocates
                develop and sustain high-quality, equitable pre-kindergarten
                (pre-K) programs that produce positive early learning
                opportunities for every child. The experience and expertise of
                pre-K systems leaders in Oregon, Tennessee, and Washington
                shaped the PPI Toolkit, resulting in a set of practical tools
                and resources that can be used widely across sectors in early
                childhood systems.
              </p>
              <p>
                Read the{' '}
                <strong>
                  <Link href="/downloads/PPIReport_Final-LoRes.pdf" >
                    report here
                  </Link>
                </strong>{' '}to learn about the PPI project and the lessons learned.
              </p>
              <p>
                <Link to="/about/" className="standalone">
                  Learn About the PPI Toolkit
                </Link>
              </p>
            </Col>
            <Col lg={{ offset: 1, span: 6 }}></Col>
          </Row>
        </Container>
      </div>
      <Container className="my-lg-5 py-lg-5">
        <Row>
          <Col lg={6}>
            <h2>Learning Together</h2>
            <p>
              The PPI toolkit will help state pre-K teams, and broader state
              pre-K stakeholders, navigate an overall vision and approach for
              statewide equity-driven pre-K improvement. Central to the toolkit
              is the{' '}
              <Link to="/app/idm/">
                <strong>Implementation Development Map (IDM)</strong>
              </Link>
              . The IDM is a process-oriented tool that allows for a subset of
              stakeholders to take strategic action in enacting the broader
              vision. Surrounding the IDM are a set of tools that support the
              overall improvement process.
            </p>
          </Col>
          <Col lg={{ span: 5, offset: 1 }} className="mt-1 d-none d-lg-block">
            <div className="background-idm-light-gray p-5 quote-box show-quotes">
              <p className="quote-text">
                We joined the PPI because, while we were growing rapidly, we
                needed to increase our system building capacity and define
                quality programs. Through our continued participation, we have
                instilled continuous quality improvement and supports to measure
                quality throughout our system.
              </p>
              <div className="quote-bar" />
              <div>
                <strong>Gwyn Bachtle</strong>
                <br /> Director of Early Learning Programs
                <br />
                Oregon Department of Education
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="background-idm-light-gray">
        <Container className="py-5">
          <Row className="my-lg-5">
            <Col>
              <h2>Using the Toolkit to Improve Pre-K Programs and Practices</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="pr-5 my-3 my-lg-0">
              <Image
                src="/images/icon_equity.svg"
                height="90"
                className="mb-4"
                alt=""
              />
              <h3>Engage Stakeholders & Build Partnerships</h3>
              <p>
                Learn how you can work with stakeholders to align on a shared
                vision for pre-K systems improvement that will support
                high-quality programs and practices that produce equitable
                outcomes for children.
              </p>
              <p>
                <Link
                  to="/tools/?topic=Engage Stakeholders %26 Build Partnerships"
                  className="standalone"
                >
                  Explore Tools
                </Link>
              </p>
            </Col>
            <Col lg={4} className="pr-5 my-3 my-lg-0">
              <Image
                src="/images/icon_assessment.svg"
                height="90"
                className="mb-4"
                alt=""
              />
              <h3>Assess the System</h3>
              <p>
                Employ the Implementation Development Map (IDM) to describe,
                assess, and identify priority areas for improvement of your
                state pre-K infrastructure and program/policy implementation.
              </p>
              <p>
                <Link
                  to="/tools/?topic=Assess the System"
                  className="standalone"
                >
                  Explore Tools
                </Link>
              </p>
            </Col>
            <Col lg={4} className="pr-5 my-3 my-lg-0">
              <Image
                src="/images/icon_improvement.svg"
                height="90"
                className="mb-4"
                alt=""
              />

              <h3>Implement, Improve &amp; Advocate</h3>
              <p>
                Access resources to help you plan and implement equity-driven
                improvements to your pre-K systems infrastructure.
              </p>
              <p>
                <Link to="/tools/?topic=Implement" className="standalone">
                  Explore Tools
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt-5 mb-lg-5 py-lg-5">
        <Row>
          <Col lg={6}>
            <h2>
              What are the Essential Elements for Implementing High-Quality
              Pre-K Systems?
            </h2>
            <p>
              Understanding the developmental phases of infrastructure and
              capacity building is critical to improving quality in equitable
              pre-K programs. The{' '}
              <strong>Implementation Development Map (IDM)</strong> defines
              essential elements of high-quality pre-K and key indicators of
              recommended research-based practices. The IDM can be used as a
              continuous quality improvement tool to identify a developmental
              progression of these practices and assess, through implementation
              data, how each indicator lands in practice.
            </p>
            <p>
              <Link to="/app/idm/" className="standalone">
                Try the IDM Tool
              </Link>
            </p>

            {indicators.map((itm) => (
              <div
                key={itm.id}
                className="my-3 my-lg-5 py-2"
                id={`${itm.group}Block`}
              >
                <div className="d-flex  ">
                  <div>
                    <img
                      src={`/images/icon_${itm.group}.svg`}
                      width="57"
                      alt=""
                    />
                  </div>
                  <span className="pl-3 pb-0 pt-2">
                    <h3>{itm.area}</h3>
                  </span>
                </div>
                <p>{itm.summary}</p>
              </div>
            ))}
          </Col>
          <Col lg={6} className="d-none d-lg-block">
            <p className="text-right sticky-top">
              <Framework />
            </p>
          </Col>
        </Row>
      </Container>
      <div className="background-idm-light-gray">
        <Container className="py-lg-5 py-3 ">
          <Row className="py-3">
            <Col lg={5}>
              <h2 className="mt-0 mb-3 mb-lg-0">Contact Us</h2>
            </Col>
            <Col lg={5}>
              <p className="my-0">
                For questions about the Partnership for Pre-K Improvement
                Toolkit, please contact{' '}
                <a href="mailto:ppitoolkitinfo@uw.edu">ppitoolkitinfo@uw.edu</a>
                .
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </DefaultLayout>
  );
};

export default IndexPage;
